<template>
  <div>
    <loadMore
      :endpoint="suggestedNewsroomsEndpoint"
      :fetching-text="$t('pages.dashboard.fetching_suggested_companies')"
      width="column is-4 is-flex-tablet is-column"
    >
      <template slot="item" slot-scope="props">
        <newsroomCard
          :newsroom="props.item"
        />
      </template>
    </loadMore>
  </div>
</template>
<script>
import NewsroomCard from '@/components/core/NewsroomCard'

export default {
  components: {
    NewsroomCard
  },
  data () {
    return {
      suggestedNewsroomsEndpoint: 'user/suggested/newsrooms?include=country'
    }
  }
}
</script>
